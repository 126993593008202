export const colorsTheme = {
  white: '#000000',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#2E308F',
  'gradient-secondary': '#2E308F',

  'primary-color': '#FECA38',
  'secondary-color': '#FECA38',
  'auxiliary-color': '#6366FF',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#020330',
  'bg-auxiliary-color': '#EDEDED',

  'bg-circle-color': '#FFFFFF',
  'bg-circle-hover': '#6366FF',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#FECA38',
  'filter-button-color': '#000',
  'filter-button-border': '#FECA38',
  'entry-message-color': '#FECA38',

  'bg-modal-color': '#FFFFFF',

  'dark-color': '#3F3F3F',

  'text-primary': '#1E1E1E',
  'text-secondary': '#2EB093;',
  'text-gray': '#4F4F4F',

  'input-color': '#1E1E1E',
  'bg-input-color': '#FFFFFF',
  'info-color': '#B5D3FF',
  'disabled-color': '#e2e2e2',
  'card-color': '#EEEEEE',
  'modal-border-color': '#ADB5BD',
  'border-color': '#6366FF',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#FECA38',
  'echeck-bar-color2': '#FECA38'
};
